import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Chip,
  Checkbox,
  FormControlLabel,
  Menu,
  Button,
  MenuItem,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { OrderStateType } from "../../orderStateType";
import NumberFormatComponent from "../../../../components/NumberFormatComponent";
import ControlledTextField from "../../../../components/ControlledTextField";
import AdminButtonComponent from "../../../../components/AdminButtonComponent";
import {
  acceptInvoice,
  deleteCreditNote,
  editRefs,
  forwardInvoice,
  generateCreditNote,
  markAsPaid,
  rejectInvoice,
  updateOfferDiscount,
  updateProduceItemInOffer,
} from "../../../../services/surplus-allocation";
import AdminModalComponent from "../../../../components/AdminModalComponent";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import ControlledComboBox from "../../../../components/ControlledComboBox";
import { toast } from "react-toastify";
import { MoreVert } from "@mui/icons-material";
import { InvoicesState } from "./InvoiceInterface";
import usePermissions from "../../../../hooks/usePermissions";
import PermissionWrapper from "../../../../components/PermissionWrapper";
import { InvoiceStatuses } from "../../../../constants";
import { AdditionalCost } from "../../orderStateType";

import { useWatch } from "react-hook-form";
import ControlledDateField from "../../../../components/ControlledDateField";

const useStyles = makeStyles({
  codeBox: {
    background: "#F5F2F1",
    display: "flex",
    flexDirection: "column",
    gap: 5,
    padding: "12px 0 16px 12px",
  },
  AmountBox: {
    padding: 20,
    flexDirection: "column",
    gap: 5,
  },
  AmountItemBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  invoiceBox: {
    padding: 10,
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  invoiceItemBox: {
    width: "200px",
    fontSize: 14,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 15,
  },
  invoiceFileType: {
    borderRadius: 20,
    width: 50,
    height: 50,
  },
  invoiceText: {
    gap: 7,
    display: "flex",
    fontSize: 16,
    cursor: "pointer",
    color: "#04B0ED",
  },
  invoiceBoxHeader: {
    fontWeight: 700,
    fontSize: 20,
  },
  invoiceMapBox: {
    paddingTop: 10,
    paddingBottom: 20,
  },
  editButton: {
    borderRadius: "10px!important",
    background: "#E77228!important",
  },
  accept: {
    borderRadius: "10px!important",
    background: "#69CDAE!important",
  },
  reject: {
    borderRadius: "10px!important",
    background: "red!important",
  },
  forward: {
    borderRadius: "10px!important",
    background: "#04B0ED!important",
  },
  confirmButton: {
    width: "50%",
    height: "50px",
    background: "#E77228 !important",
    borderRadius: "20px !important",
  },
  creditNoteCancelButton: {
    background: "red !important",
  },
  closeButton: {
    width: "50%",
    height: "50px",
    background: "white !important",
    border: "1px solid #E77228 !important",
    color: "#E77228 !important",
    borderRadius: "20px !important",
  },
  filterModalButtons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    gap: 20,
  },
  comboBox: {
    background: "transparent",
    height: "auto",
    fontSize: 16,
    width: "100%",

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",
      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `#69CDAE !important`,
    },
    "&::before": {
      borderColor: "#E8E8E8",
    },
    "& .MuiAutocomplete-tag": {
      width: 80,
      background: "#69CDAE",
      borderRadius: 20,
      color: "#FFF",
      "& .MuiChip-deleteIcon": {
        color: "#FFF",
      },
    },
  },
  textField: {
    background: "#F8F8F8",
    fontSize: 20,
    borderRadius: 10,
    border: "none !important",
    width: "100%",
    height: 50,
    padding: 15,
    "& .MuiFormHelperText-root.Mui-error": {
      color: "red !important",
    },
    "& .MuiInputBase-root": {
      background: "#f8f8f8",
      minHeight: 50,
      borderColor: "black !important",
      border: "none !important",
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
  },
  creditNoteTextField: {
    textAlign: "right",
    background: "#F8F8F8",
    fontSize: 20,
    borderRadius: 10,
    border: "none !important",
    width: "100%",
    height: 35,
    padding: 15,
    "& .MuiFormHelperText-root.Mui-error": {
      color: "red !important",
    },
    "& .MuiInputBase-root": {
      textAlign: "right",
      background: "#f8f8f8",
      minHeight: 35,
      borderColor: "black !important",
      border: "none !important",
      fontSize: "14px",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
  },
  discountBox: {
    display: "flex",
    alignItems: "center",
    color: "#E77228",
    fontSize: 16,
  },
  updateDiscountButton: {
    width: "115%",
    borderRadius: "10px!important",
    background: "#E77228!important",
  },
  trash: {
    cursor: "pointer",
    fontSize: 19,
    color: "red",
  },
  hr: {
    margin: 20,
    opacity: 0.07,
  },
  orderConfirmationBox: {
    padding: 15,
  },
  orderConfirmationText: {
    textDecoration: "underline",
    color: "#E77228",
    cursor: "pointer",
  },
  approvedText: {
    color: "#69cdae",
  },
  rejectedText: {
    color: "#F6133D",
  },
  inputRefs: {
    backgroundColor: "white",
    fontSize: 20,
    borderRadius: 10,
    border: "none !important",
    width: "auto",
    height: 35,
    padding: 15,
    "& .MuiFormHelperText-root.Mui-error": {
      color: "red !important",
    },
    "& .MuiInputBase-root": {
      background: "white",
      minHeight: 35,
      borderColor: "black !important",
      border: "none !important",
      fontSize: "1rem",
      borderRadius: 10,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
  },
  produceNameStyle: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: 60,
  },
});

interface VATSummary {
  [vatRate: number]: {
    totalVAT: number;
  };
}

function calculateVATSummary(costs: AdditionalCost[]): VATSummary {
  const summary: VATSummary = {};

  for (const cost of costs) {
    const vatAmount = (cost.vatRate / 100) * cost.amount;

    if (!summary[cost.vatRate]) {
      summary[cost.vatRate] = { totalVAT: 0 };
    }

    summary[cost.vatRate].totalVAT += vatAmount;
  }

  return summary;
}

interface PropsType {
  errors: any;
  setOrderData: React.Dispatch<React.SetStateAction<any>>;
  setRefresh: React.Dispatch<React.SetStateAction<number>>;
  setRefreshPartial: React.Dispatch<React.SetStateAction<number>>;
  watch: any;
  setValue: any;
  control: any;
  orderData: OrderStateType | undefined;
  invoices: InvoicesState[];
}

const ProduceInformationBox: React.FC<PropsType> = ({
  orderData,
  invoices,
  setRefreshPartial,
  setOrderData,
  setRefresh,
  control,
  watch,
  errors,
  setValue,
}) => {
  const { hasPermission } = usePermissions();
  const classes = useStyles();

  const grandTotal: number | undefined = orderData?.grandTotalAmount
    ? orderData.grandTotalAmount
    : 0;

  const [isAcceptRejecting, setIsAcceptRejecting] = useState<boolean>(false);
  const [editLoading, setEditLoading] = useState<boolean>(false);
  const [isEditBuyerRef, setIsEditBuyerRef] = useState<boolean>(false);
  const [isEditSellerRef, setIsEditSellerRef] = useState<boolean>(false);
  const [buyerRefEditing, setBuyerRefEditing] = useState(false);
  const [sellerRefEditing, setSellerRefEditing] = useState(false);
  const [forwardLoading, setIsForwardLoading] = useState<boolean>(false);
  const [paidLoading, setIsPaidLoading] = useState<boolean>(false);
  const [generateLoading, setGenerateLoading] = useState<boolean>(false);
  const [deleteCreditNoteLoading, setDeleteCreditNoteLoading] =
    useState<boolean>(false);
  const [openAcceptRejectConfirmModal, setOpenAcceptRejectConfirmModal] =
    useState<boolean>(false);

  const [openPaidConfirmModal, setOpenPaidConfirmModal] =
    useState<boolean>(false);
  const [
    openDeleteCreditNoteConfirmModal,
    setOpenDeleteCreditNoteConfirmModal,
  ] = useState<boolean>(false);
  const [openGenerateCreditNoteModal, setOpenGenerateCreditNoteModal] =
    useState<boolean>(false);
  const [openForwardInvoiceModal, setOpenForwardInvoiceModal] =
    useState<boolean>(false);
  const [isForwardingBuyer, setIsForwardingBuyer] = useState<boolean>(false);
  const [isForwardingSeller, setIsForwardingSeller] = useState<boolean>(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string>("");
  const [selectedCreditNoteId, setSelectedCreditNoteId] = useState<string>("");
  const [modalRenderType, setModalRenderType] = useState<string>("");
  const [isUpdatingDiscount, setIsUpdatingDiscount] = useState<boolean>(false);
  const [selectedCreditNoteProduceIndex, setSelectedCreditNoteProduceIndex] =
    useState(-1);

  const discountType = watch("discountType");
  const creditNoteAmount = watch("creditNoteAmount");
  const discountTypeValue = watch("discountTypeValue");
  const markAsPaidDate = watch("markAsPaidDate");

  const creditNoteProduces = useWatch({
    control,
    name: "creditNoteProduces",
  });

  const creditNoteProduceIndexQTY = watch(
    `creditNoteProduces[${selectedCreditNoteProduceIndex}].quantity`
  );

  const totalCreditNoteProducesAmount = useMemo(() => {
    if (!creditNoteProduces || creditNoteProduces.length === 0) {
      return 0;
    }
    return creditNoteProduces
      .map((p: any) => parseFloat(p.total))
      .reduce((prev: number, curr: number) => prev + curr, 0);
  }, [creditNoteProduces]);

  const isCanAcceptOrReject = orderData?.produces
    .map((item) => item.dealPriceIndication)
    .every((item) => item);

  const cannotEditProduceOrCannotDiscount =
    orderData?.status === "UNREACHABLE" ||
    orderData?.status === "PENDING" ||
    orderData?.status === "INVOICED" ||
    orderData?.status === "CANCELLED" ||
    orderData?.status === "BUYER TO PAY" ||
    orderData?.status === "COMPLETE" ||
    (orderData?.invoices?.length
      ? orderData?.invoices
          ?.map((item: any) => item?.status)
          ?.every((item: any) => item !== "DRAFT" && item !== "PROCESSING")
      : false);

  useEffect(() => {
    if (orderData?._id) {
      setValue(
        "discountTypeValue",
        orderData?.discount?.discountTypeValue?.toString()
      );
      setValue(
        "discountType",
        orderData?.discount?.discountType === "PERCENTAGE"
          ? "Percentage"
          : orderData?.discount?.discountType === "FIXED"
          ? "Amount"
          : ""
      );
    }
  }, [
    orderData?._id,
    setValue,
    orderData?.discount?.discountType,
    orderData?.discount?.discountTypeValue,
  ]);

  //generate credit note produce item default values set
  useEffect(() => {
    setValue(
      "creditNoteProduces",
      orderData?.produces.map((p, index) => ({
        total: (p.dealPriceIndication * p.quantity).toFixed(2),
        quantity: p.quantity,
      }))
    );
  }, [orderData?.produces, setValue, watch]);

  useEffect(() => {
    if (creditNoteProduceIndexQTY) {
      setValue(
        `creditNoteProduces[${selectedCreditNoteProduceIndex}].total`,
        (
          creditNoteProduceIndexQTY *
          (orderData?.produces[selectedCreditNoteProduceIndex]
            .dealPriceIndication
            ? orderData?.produces[selectedCreditNoteProduceIndex]
                .dealPriceIndication
            : 0)
        ).toFixed(2)
      );
    }
  }, [
    selectedCreditNoteProduceIndex,
    watch,
    orderData?.produces,
    setValue,
    creditNoteProduceIndexQTY,
  ]);

  const onUpdateDiscount = async () => {
    if (!hasPermission("ORDERS_ORDER_DETAILS_EDIT")) return;

    setIsUpdatingDiscount(true);

    const data = {
      discount: {
        discountType:
          discountType === "Amount"
            ? "FIXED"
            : discountType === "Percentage"
            ? "PERCENTAGE"
            : "",
        discountTypeValue: discountTypeValue
          ? parseFloat(discountTypeValue)
          : 0,
      },
      offerId: orderData?._id,
    };

    if (!discountType) {
      toast.error("Please select your discount type");
      setIsUpdatingDiscount(false);
    } else {
      await updateOfferDiscount(data);
      setIsUpdatingDiscount(false);
      setRefresh((prev: any) => prev + 1);
      setRefreshPartial((prev) => prev + 1);
    }
  };

  const onOpenAcceptRejectConfirmModal = (id: string, type: string) => {
    setOpenAcceptRejectConfirmModal(true);
    setSelectedInvoiceId(id);
    setModalRenderType(type);
  };

  const onOpenForwardInvoiceModal = (id: string) => {
    setOpenForwardInvoiceModal(true);
    setSelectedInvoiceId(id);
  };

  const onOpenGenerateCreditNoteModal = (id: string) => {
    setOpenGenerateCreditNoteModal(true);
    setSelectedInvoiceId(id);
  };

  const onOpenPaidConfirmModal = (id: string) => {
    setOpenPaidConfirmModal(true);
    setSelectedInvoiceId(id);
  };

  const onOpenDeleteCreditNoteConfirmModal = (
    id: string,
    creditNoteId: string
  ) => {
    setOpenDeleteCreditNoteConfirmModal(true);
    setSelectedInvoiceId(id);
    setSelectedCreditNoteId(creditNoteId);
  };
  const onCloseForwardInvoiceModal = () => {
    setOpenForwardInvoiceModal(false);
    setSelectedInvoiceId("");
  };

  const onCloseAcceptRejectConfirmModal = () => {
    setOpenAcceptRejectConfirmModal(false);
    setSelectedInvoiceId("");
    setModalRenderType("");
  };
  const onCloseGenerateCreditNoteModal = () => {
    setOpenGenerateCreditNoteModal(false);
    setSelectedInvoiceId("");
    setValue("creditNoteAmount", "");
  };
  const onCloseDeleteCreditNoteConfirmModal = () => {
    setOpenDeleteCreditNoteConfirmModal(false);
    setSelectedInvoiceId("");
  };
  const onClosePaidConfirmModal = () => {
    setOpenPaidConfirmModal(false);
    setSelectedInvoiceId("");
  };

  const onEditProduce = async () => {
    if (!hasPermission("ORDERS_ORDER_DETAILS_EDIT")) return;

    const finalData = {
      updatedProduces: watch("produces")?.map((p: any, index: number) => ({
        _id: orderData?.produces[index]._id,
        priceGuide: p?.priceGuide ? p?.priceGuide : 0,
        dealPriceIndication: p?.dealPriceIndication
          ? p?.dealPriceIndication
          : null,
        quantity: p?.quantity ? p?.quantity : 0,
      })),
    };

    setEditLoading(true);
    const updated = await updateProduceItemInOffer(
      finalData,
      orderData?._id ? orderData?._id : ""
    );
    setEditLoading(false);

    if (updated) {
      setRefreshPartial((prev) => prev + 1);
      setRefresh((prev: any) => prev + 1);
    }
  };

  const onAcceptRejectInvoice = async () => {
    if (!hasPermission("INVOICE_ACCEPT_DRAFT_INVOICE")) return;

    if (modalRenderType === "reject") {
      setIsAcceptRejecting(true);

      const rejected = await rejectInvoice(
        orderData?._id ? orderData?._id : "",
        selectedInvoiceId
      );
      setIsAcceptRejecting(false);

      setRefresh((prev) => prev + 1);
      setRefreshPartial((prev) => prev + 1);
      setOpenAcceptRejectConfirmModal(false);
      setSelectedInvoiceId("");
      setModalRenderType("");
    } else {
      setIsAcceptRejecting(true);

      const data = {
        invoiceId: selectedInvoiceId,
        type: "ISSUED",
        refs: {
          buyerReferenceNumber: orderData?.refs.buyerReferenceNumber,
          sellerReferenceNumber: orderData?.refs.sellerReferenceNumber,
        },
      };

      const accepted = await acceptInvoice(data);
      setIsAcceptRejecting(false);

      setRefresh((prev) => prev + 1);
      setRefreshPartial((prev) => prev + 1);
      setOpenAcceptRejectConfirmModal(false);
      setSelectedInvoiceId("");
      setModalRenderType("");
    }
  };

  const onForwardInvoice = async () => {
    if (!hasPermission("ORDERS_ORDER_DETAILS_EDIT")) return;

    if (!isForwardingBuyer && !isForwardingSeller) {
      toast.error("Please select at least 1 customer");
    } else {
      setIsForwardLoading(true);

      const data = {
        invoiceId: selectedInvoiceId,
        sendToBuyer: isForwardingBuyer,
        sendToSeller: isForwardingSeller,
      };
      const forwarded = await forwardInvoice(data);

      setIsForwardLoading(false);

      if (forwarded) {
        setOpenForwardInvoiceModal(false);
        setSelectedInvoiceId("");
        setIsForwardingBuyer(false);
        setIsForwardingSeller(false);
        setRefresh((prev) => prev + 1);
      }
    }
  };

  const onGenerateCreditNote = useCallback(async () => {
    if (!hasPermission("ORDERS_ORDER_DETAILS_EDIT")) return;

    setGenerateLoading(true);
    const finalData = {
      invoiceId: selectedInvoiceId,
      produces: creditNoteProduces?.map(
        (i: { total: string; quantity: string }, index: number) => ({
          amount: parseFloat(i?.total ?? "0").toFixed(2),
          produceId: orderData?.produces[index]._id,
          receivedQuantity: parseFloat(i.quantity ?? "0"),
        })
      ),
    };
    const generated = await generateCreditNote(finalData);
    setGenerateLoading(false);

    if (generated) {
      setValue("creditNoteAmount", "");
      setRefresh((prev: number) => prev + 1);
      setOpenGenerateCreditNoteModal(false);
      setSelectedInvoiceId("");
    }
  }, [
    creditNoteProduces,
    hasPermission,
    orderData?.produces,
    selectedInvoiceId,
    setRefresh,
    setValue,
  ]);

  const onDeleteCreditNote = async () => {
    if (!hasPermission("ORDERS_ORDER_DETAILS_EDIT")) return;

    setDeleteCreditNoteLoading(true);
    const deleted = await deleteCreditNote(
      selectedInvoiceId,
      selectedCreditNoteId
    );
    setDeleteCreditNoteLoading(false);

    if (deleted) {
      setRefresh((prev: number) => prev + 1);
      setOpenDeleteCreditNoteConfirmModal(false);
      setSelectedInvoiceId("");
      setSelectedCreditNoteId("");
    }
  };

  const onMarkAsPaid = useCallback(async () => {
    if (
      !markAsPaidDate?.toString() ||
      markAsPaidDate?.toString() === "Invalid Date"
    ) {
      toast.error("Please select a date");
    } else {
      setIsPaidLoading(true);
      const paid = await markAsPaid(
        orderData?._id || "",
        selectedInvoiceId,
        moment(markAsPaidDate?.toString()).format("DD/MM/YYYY")
      );
      setIsPaidLoading(false);

      if (paid) {
        setOpenPaidConfirmModal(false);
        setSelectedInvoiceId("");
        setRefresh((prev: number) => prev + 1);
      }
    }
  }, [selectedInvoiceId, orderData?._id, markAsPaidDate, setRefresh]);

  const onEditRefs = useCallback(
    async (type: string) => {
      if (type === "buyer") {
        setBuyerRefEditing(true);
        const finalBuyerRefData = {
          type: "buyer",
          offerId: orderData?._id,
          refs: {
            buyerReferenceNumber: watch("buyerRef"),
            sellerReferenceNumber: watch("sellerRef"),
          },
        };

        const updatedBuyerRef = await editRefs(finalBuyerRefData);

        setBuyerRefEditing(false);

        if (updatedBuyerRef?.success) {
          setIsEditBuyerRef(false);
          let newOrderBuyerUpdate = { ...orderData };
          newOrderBuyerUpdate.refs = updatedBuyerRef?.refs;
          setOrderData(newOrderBuyerUpdate);
        }
      } else {
        setSellerRefEditing(true);
        const finalBuyerRefData = {
          type: "seller",
          offerId: orderData?._id,
          refs: {
            buyerReferenceNumber: watch("buyerRef"),
            sellerReferenceNumber: watch("sellerRef"),
          },
        };

        const updatedSellerRef = await editRefs(finalBuyerRefData);
        setSellerRefEditing(false);

        if (updatedSellerRef?.success) {
          setIsEditSellerRef(false);
          let newOrderSellerUpdate = { ...orderData };
          newOrderSellerUpdate.refs = updatedSellerRef?.refs;
          setOrderData(newOrderSellerUpdate);
        }
      }
    },
    [orderData, setOrderData, watch]
  );

  const RenderInvoiceItem = ({
    index,
    invoice,
  }: {
    index: number;
    invoice: InvoicesState;
  }) => {
    const { invoiceApproval } = invoice;
    const [openInvoiceMenu, setOpenInvoiceMenu] = useState(null);

    const invoiceChipColor = InvoiceStatuses.find(
      (i) => i.name === invoice.status
    )?.color;

    const isBuyerApproved = invoiceApproval.find(
      (item) => item.approvingParty === "BUYER" && item.approval === "APPROVED"
    );

    const isSellerApproved = invoiceApproval.find(
      (item) => item.approvingParty === "SELLER" && item.approval === "APPROVED"
    );
    const isBuyerRejected = invoiceApproval.find(
      (item) => item.approvingParty === "BUYER" && item.approval === "REJECTED"
    );
    const isSellerRejected = invoiceApproval.find(
      (item) => item.approvingParty === "SELLER" && item.approval === "REJECTED"
    );

    const open = Boolean(openInvoiceMenu);
    const handleClick = (event: any) => {
      setOpenInvoiceMenu(event.currentTarget);
    };
    const handleClose = () => {
      setOpenInvoiceMenu(null);
    };
    return (
      <>
        <div
          key={index}
          className="d-flex mt-2  justify-content-between align-items-center"
        >
          <div className="d-flex flex-row gap-3 align-items-center">
            <a
              style={{
                textDecoration: "none",
              }}
              href={invoice?.pdfPath}
              target="_blank"
              rel="noreferrer"
            >
              <Typography className={classes.invoiceText}>
                <img src="/static/svg/ic_file_pdf.svg" />
                {invoice?.code}
              </Typography>
            </a>
            <Chip
              style={{
                background: invoiceChipColor,
                color: "white",
                fontWeight: 600,
                minWidth: 120,
              }}
              label={invoice.status}
            />
          </div>
          <IconButton
            aria-controls={`basic-menu-${index}`} // Unique ID for each menu
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <MoreVert />
          </IconButton>
          <Menu
            id={`basic-menu-${index}`}
            anchorEl={openInvoiceMenu}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {isCanAcceptOrReject &&
            (invoice.status === "DRAFT" || invoice.status === "PROCESSING") ? (
              <PermissionWrapper permission="INVOICE_ACCEPT_DRAFT_INVOICE">
                <MenuItem
                  onClick={() => {
                    onOpenAcceptRejectConfirmModal(invoice._id, "accept");
                    handleClose();
                  }}
                >
                  Capture Invoice
                </MenuItem>
              </PermissionWrapper>
            ) : null}
            {invoice.status === "DRAFT" || invoice.status === "PROCESSING" ? (
              <>
                <PermissionWrapper permission="INVOICE_ACCEPT_DRAFT_INVOICE">
                  <MenuItem
                    onClick={() => {
                      onOpenAcceptRejectConfirmModal(invoice._id, "reject");
                      handleClose();
                    }}
                  >
                    Reject Invoice
                  </MenuItem>
                </PermissionWrapper>
                <PermissionWrapper permission="ORDERS_ORDER_DETAILS_EDIT">
                  <MenuItem
                    onClick={() => {
                      onOpenForwardInvoiceModal(invoice._id);
                      handleClose();
                    }}
                  >
                    Forward Invoice
                  </MenuItem>
                </PermissionWrapper>
              </>
            ) : invoice.status !== "DELETED" ? (
              <PermissionWrapper permission="ORDERS_ORDER_DETAILS_EDIT">
                <MenuItem
                  onClick={() => {
                    onOpenForwardInvoiceModal(invoice._id);
                    handleClose();
                  }}
                >
                  Forward Invoice
                </MenuItem>
              </PermissionWrapper>
            ) : null}

            {invoice.status !== "DRAFT" && invoice.status !== "PROCESSING" ? (
              <PermissionWrapper permission="ORDERS_ORDER_DETAILS_EDIT">
                <MenuItem
                  onClick={() => {
                    onOpenGenerateCreditNoteModal(invoice._id);
                    handleClose();
                  }}
                >
                  Generate Credit Note
                </MenuItem>
              </PermissionWrapper>
            ) : null}
            {invoice.status === "ISSUED" ||
            invoice.status === "SELLER_PAID" ||
            invoice.status === "DUE" ? (
              <PermissionWrapper permission="ORDERS_ORDER_DETAILS_EDIT">
                <MenuItem
                  onClick={() => {
                    onOpenPaidConfirmModal(invoice._id);
                    handleClose();
                  }}
                >
                  Mark As Paid
                </MenuItem>
              </PermissionWrapper>
            ) : null}
          </Menu>
        </div>

        <div className="mt-2">
          {isBuyerApproved ? (
            <span
              className={`d-flex flex-row gap-2 align-items-center ${classes.approvedText}`}
            >
              <img src="/static/svg/ic_checked.svg" />
              Buyer Approved
            </span>
          ) : isBuyerRejected ? (
            <span
              className={`d-flex flex-row gap-2 align-items-center ${classes.rejectedText}`}
            >
              <img
                style={{ width: 23, height: 23 }}
                src="/static/svg/Close_round_fill_red.svg"
              />
              Buyer Rejected
            </span>
          ) : (
            <span className="d-flex flex-row gap-2 align-items-center">
              <img src="/static/svg/ic_unchecked.svg" />
              Waiting for buyer's approval
            </span>
          )}

          {isSellerApproved ? (
            <span
              className={`d-flex flex-row gap-2 align-items-center mt-2 ${classes.approvedText}`}
            >
              <img src="/static/svg/ic_checked.svg" />
              Seller Approved
            </span>
          ) : isBuyerRejected ? (
            <span
              className={`d-flex flex-row gap-2 align-items-center mt-2 ${classes.rejectedText}`}
            >
              <img
                style={{ width: 23, height: 23 }}
                src="/static/svg/Close_round_fill_red.svg"
              />
              Seller Rejected
            </span>
          ) : (
            <span className="d-flex flex-row gap-2 align-items-center mt-2">
              <img src="/static/svg/ic_unchecked.svg" />
              Waiting for seller's approval
            </span>
          )}
        </div>

        {invoice.creditNoteIds?.length
          ? invoice.creditNoteIds.map((i) => {
              return (
                <div
                  style={{ marginLeft: 25 }}
                  className="d-flex gap-2  flex-row align-items-center"
                >
                  <span className="fw-bold mt-1">Credit Note :</span>
                  <a
                    className="mt-1"
                    style={{
                      textDecoration: "none",
                    }}
                    href={i?.pdfUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Typography className={classes.invoiceText}>
                      {i?.code}
                    </Typography>
                  </a>
                  <PermissionWrapper permission="ORDERS_ORDER_DETAILS_EDIT">
                    <i
                      onClick={() =>
                        onOpenDeleteCreditNoteConfirmModal(invoice._id, i._id)
                      }
                      className={`ri-delete-bin-line ${classes.trash} col-md-1 mt-1`}
                    ></i>
                  </PermissionWrapper>
                </div>
              );
            })
          : null}
      </>
    );
  };

  const renderAcceptRejectInvoiceModalBody = () => {
    return (
      <>
        <div className="container">
          <span>
            {modalRenderType === "reject"
              ? "Are you sure you want to reject this invoice?"
              : modalRenderType === "accept"
              ? "Are you sure you want to capture this invoice?"
              : null}
          </span>
          <div className={classes.filterModalButtons}>
            <AdminButtonComponent
              className={classes.closeButton}
              onClick={onCloseAcceptRejectConfirmModal}
              title="Close"
            />{" "}
            <AdminButtonComponent
              className={classes.confirmButton}
              onClick={onAcceptRejectInvoice}
              title="Confirm"
            />
          </div>
        </div>
      </>
    );
  };

  const renderForwardInvoiceModalBody = () => {
    return (
      <>
        <div className="container">
          <FormControlLabel
            value={isForwardingBuyer}
            checked={isForwardingBuyer}
            onChange={(e: any) => setIsForwardingBuyer(e.target.checked)}
            control={<Checkbox style={{ color: "#e77228" }} />}
            label={`Buyer(${orderData?.buyerCompany.companyName})`}
          />
          <br />
          <FormControlLabel
            value={isForwardingSeller}
            checked={isForwardingSeller}
            onChange={(e: any) => setIsForwardingSeller(e.target.checked)}
            control={<Checkbox style={{ color: "#e77228" }} />}
            label={`Seller(${orderData?.sellerCompany.companyName})`}
          />
          <div className={classes.filterModalButtons}>
            <AdminButtonComponent
              className={classes.closeButton}
              onClick={onCloseForwardInvoiceModal}
              title="Close"
            />{" "}
            <AdminButtonComponent
              className={classes.confirmButton}
              onClick={onForwardInvoice}
              title="Confirm"
            />
          </div>
        </div>
      </>
    );
  };

  const renderGenerateCreditNoteModalBody = () => {
    return (
      <>
        <div className="container">
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: 600 }}>Produce</TableCell>
                  <TableCell style={{ fontWeight: 600 }}>Del. Date</TableCell>
                  <TableCell style={{ fontWeight: 600 }}>QTY</TableCell>
                  <TableCell style={{ fontWeight: 600 }}>Adv. Price</TableCell>
                  <TableCell style={{ fontWeight: 600 }}>Ret. Price</TableCell>
                  <TableCell style={{ fontWeight: 600 }}>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderData?.produces.map((row, index) => {
                  return (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <div className={classes.produceNameStyle}>
                          {/* <img alt="creditNoteProduceImage" width={25} height={25} src={row.photo.url}/> */}
                          {row.name}
                        </div>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {moment(orderData.deliveryDate[0].deliveryDate).format(
                          "DD/MM/YYYY"
                        )}
                      </TableCell>
                      <TableCell style={{ width: 180 }}>
                        <div className="d-flex flex-row items-center gap-2">
                          <ControlledTextField
                            alignRight
                            onFocus={() =>
                              setSelectedCreditNoteProduceIndex(index)
                            }
                            className={classes.creditNoteTextField}
                            control={control}
                            name={`creditNoteProduces[${index}].quantity`}
                          />
                          <span>{row.unit.name}</span>
                        </div>
                      </TableCell>
                      <TableCell>
                        <span className="ml-10">
                          {
                            <NumberFormatComponent
                              value={row.priceGuide}
                              prefix={row.currency.symbol}
                            />
                          }
                        </span>
                      </TableCell>
                      <TableCell>
                        <span className="ml-8">
                          {
                            <NumberFormatComponent
                              value={row.dealPriceIndication}
                              prefix={row.currency.symbol}
                            />
                          }
                        </span>
                      </TableCell>
                      <TableCell
                        className="d-flex flex-row items-center gap-1"
                        style={{ width: 150 }}
                      >
                        {" "}
                        {orderData.produces[0].currency.symbol}
                        <ControlledTextField
                          alignRight
                          onFocus={() =>
                            setSelectedCreditNoteProduceIndex(index)
                          }
                          className={classes.creditNoteTextField}
                          control={control}
                          name={`creditNoteProduces[${index}].total`}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <hr style={{ opacity: 0.07 }} className="mt-3 mb-3" />
          <div className="d-flex justify-end mr-4 gap-2 font-bold ">
            Credit Note Total Amount :{" "}
            <NumberFormatComponent
              value={totalCreditNoteProducesAmount || 0}
              prefix={orderData?.produces[0].currency.symbol}
            />
          </div>

          <hr style={{ opacity: 0.07 }} className="mt-3 mb-3" />
          <div className="d-flex justify-end mr-4  gap-2">
            <AdminButtonComponent
              style={{ minWidth: 100 }}
              disabled={generateLoading}
              className={classes.creditNoteCancelButton}
              onClick={() => setOpenGenerateCreditNoteModal(false)}
              title="Cancel"
            />
            <AdminButtonComponent
              style={{ minWidth: 100 }}
              disabled={generateLoading}
              loading={generateLoading}
              onClick={onGenerateCreditNote}
              title="Create"
            />
          </div>
        </div>
      </>
    );
  };

  const renderDeleteCreditNoteConfirmModalBody = () => {
    return (
      <>
        <div className="container">
          <span>Are you sure you want to delete this credit note?</span>
          <div className={classes.filterModalButtons}>
            <AdminButtonComponent
              disabled={deleteCreditNoteLoading}
              className={classes.closeButton}
              onClick={onCloseDeleteCreditNoteConfirmModal}
              title="Close"
            />{" "}
            <AdminButtonComponent
              disabled={deleteCreditNoteLoading}
              loading={deleteCreditNoteLoading}
              className={classes.confirmButton}
              onClick={onDeleteCreditNote}
              title="Confirm"
            />
          </div>
        </div>
      </>
    );
  };



  const renderPaidConfirmModalBody = () => {
    return (
      <>
        <div className="container">
          <ControlledDateField
            textLabel="Date"
            control={control}
            name={`markAsPaidDate`}
          />

          <div className="mt-3">
            {markAsPaidDate?.toString() &&
            markAsPaidDate?.toString() !== "Invalid Date" ? (
              <span>
                {` Are you sure to mark this invoice as paid on ${moment(
                  markAsPaidDate.toString()
                ).format("DD/MM/YYYY")}?`}
              </span>
            ) : null}
          </div>
          <div className={classes.filterModalButtons}>
            <AdminButtonComponent
              disabled={paidLoading}
              className={classes.closeButton}
              onClick={onClosePaidConfirmModal}
              title="Close"
            />{" "}
            <AdminButtonComponent
              disabled={paidLoading}
              loading={paidLoading}
              className={classes.confirmButton}
              onClick={onMarkAsPaid}
              title="Confirm"
            />
          </div>
        </div>
      </>
    );
  };

  const calculatedVATSummary = useMemo(() => {
    const summary = calculateVATSummary(orderData?.additionalCosts ?? []);
    return Object.keys(summary).map((key) => ({
      vatRate: key,
      totalVAT: summary[key as any].totalVAT,
    }));
  }, [orderData?.additionalCosts]);

  return (
    <>
      <Typography style={{ padding: 20, fontSize: 20, fontWeight: 500 }}>
        Produce Details
      </Typography>
      {isAcceptRejecting && <LoadingIndicator loading={isAcceptRejecting} />}
      {editLoading && <LoadingIndicator loading={editLoading} />}
      {isUpdatingDiscount && <LoadingIndicator loading={isUpdatingDiscount} />}
      {forwardLoading && <LoadingIndicator loading={forwardLoading} />}

      <Box style={{ padding: 10 }}>
        <Box className={classes.codeBox}>
          <Typography style={{ fontWeight: 700, fontSize: 20 }}>
            {orderData?.code} -{" "}
            <a
              href={`${process.env.REACT_APP_FINMID_URL}/invoices/${orderData?.offerCode}`}
              target="_blank"
              style={{ color: "#E77228", textDecoration: "underline" }}
              rel="noreferrer"
            >
              {orderData?.offerCode}
            </a>
          </Typography>
          <Typography
            style={{ color: "#A14F1C", fontSize: 16, fontWeight: 400 }}
          >
            {orderData?.seller?.fullName} -
            {orderData?.sellerCompany?.companyName}
          </Typography>

          <Box className="d-flex flex-column gap-3 mt-3">
            <Box className="d-flex flex-row align-items-center gap-3">
              <span className="fw-bold">Seller Reference Number :</span>{" "}
              <Box className="col-md-4">
                {isEditSellerRef ? (
                  sellerRefEditing ? (
                    <CircularProgress size={25} />
                  ) : (
                    <Box className="d-flex flex-row align-items-center gap-2">
                      <ControlledTextField
                        disabled={sellerRefEditing}
                        className={classes.inputRefs}
                        control={control}
                        name="sellerRef"
                      />
                      {buyerRefEditing ? null : (
                        <>
                          <img
                            onClick={() => onEditRefs("seller")}
                            className="cursor-pointer"
                            src="/static/svg/Check_round_fill_green_thick.svg"
                          />
                          <img
                            onClick={() => setIsEditSellerRef(false)}
                            className="cursor-pointer"
                            src="/static/svg/Close_round_fill_red.svg"
                          />
                        </>
                      )}
                    </Box>
                  )
                ) : (
                  <Box className="d-flex flex-row align-items-center gap-3">
                    {" "}
                    <span>
                      {orderData?.refs.sellerReferenceNumber || "N/A"}
                    </span>
                    {buyerRefEditing ? null : (
                      <PermissionWrapper permission="ORDERS_ORDER_DETAILS_EDIT">
                        <img
                          onClick={() => setIsEditSellerRef(true)}
                          className="cursor-pointer"
                          src="/static/svg/Frame 2253.svg"
                        />
                      </PermissionWrapper>
                    )}
                  </Box>
                )}
              </Box>
            </Box>

            <Box className="d-flex flex-row align-items-center gap-3">
              <span className="fw-bold">Buyer Reference Number :</span>{" "}
              <Box className="col-md-4">
                {isEditBuyerRef ? (
                  buyerRefEditing ? (
                    <CircularProgress size={25} />
                  ) : (
                    <Box className="d-flex flex-row align-items-center gap-2">
                      <ControlledTextField
                        disabled={buyerRefEditing}
                        className={classes.inputRefs}
                        control={control}
                        name="buyerRef"
                      />
                      {sellerRefEditing ? null : (
                        <>
                          <img
                            onClick={() => onEditRefs("buyer")}
                            className="cursor-pointer"
                            src="/static/svg/Check_round_fill_green_thick.svg"
                          />
                          <img
                            onClick={() => setIsEditBuyerRef(false)}
                            className="cursor-pointer"
                            src="/static/svg/Close_round_fill_red.svg"
                          />
                        </>
                      )}
                    </Box>
                  )
                ) : (
                  <Box className="d-flex flex-row align-items-center gap-3">
                    <span>{orderData?.refs.buyerReferenceNumber || "N/A"}</span>
                    {sellerRefEditing ? null : (
                      <PermissionWrapper permission="ORDERS_ORDER_DETAILS_EDIT">
                        <img
                          onClick={() => setIsEditBuyerRef(true)}
                          className="cursor-pointer"
                          src="/static/svg/Frame 2253.svg"
                        />
                      </PermissionWrapper>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontSize: 16 }}>Produce</TableCell>
              <TableCell style={{ fontSize: 16 }}>Delivery Date</TableCell>
              <TableCell style={{ fontSize: 16 }}>Quantity</TableCell>
              <TableCell style={{ fontSize: 16 }}>Price Guide</TableCell>
              <TableCell style={{ fontSize: 16 }}>Return Price</TableCell>
              <TableCell style={{ fontSize: 16 }}>Total Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderData?.produces?.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell className={classes.invoiceItemBox} component="th">
                  <div>
                    <img
                      className={classes.invoiceFileType}
                      src={row?.photo?.url}
                    />{" "}
                  </div>
                  <span>{row.name}</span>
                </TableCell>
                <TableCell style={{ fontSize: 14 }}>
                  {moment(orderData.deliveryDate?.[0]?.deliveryDate).format(
                    "DD/MM/YYYY"
                  )}
                </TableCell>
                <TableCell style={{ fontSize: 14, width: 150 }}>
                  <ControlledTextField
                    disabled={cannotEditProduceOrCannotDiscount}
                    defaultValue={row.quantity?.toString()}
                    control={control}
                    name={`produces[${index}].quantity`}
                  />
                </TableCell>
                <TableCell style={{ fontSize: 14, width: 250 }}>
                  <ControlledTextField
                    disabled={cannotEditProduceOrCannotDiscount}
                    defaultValue={row.priceGuide?.toString()}
                    control={control}
                    name={`produces[${index}].priceGuide`}
                  />
                  {/* <NumberFormatComponent
                    prefix={row?.currency?.symbol}
                    value={row.priceGuide}
                  /> */}
                </TableCell>
                <TableCell style={{ fontSize: 14, width: 250 }}>
                  <ControlledTextField
                    disabled={cannotEditProduceOrCannotDiscount}
                    defaultValue={row.dealPriceIndication?.toString()}
                    control={control}
                    name={`produces[${index}].dealPriceIndication`}
                  />
                  {/* <NumberFormatComponent
                    prefix={row?.currency?.symbol}
                    value={row.dealPriceIndication}
                  /> */}
                </TableCell>
                <TableCell style={{ color: "#E77228", fontSize: 14 }}>
                  <NumberFormatComponent
                    prefix={row?.currency?.symbol}
                    value={
                      row.dealPriceIndication
                        ? row.dealPriceIndication * row?.quantity
                        : row.priceGuide * row?.quantity
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box className="d-flex justify-content-end mr-5">
        <PermissionWrapper permission="ORDERS_ORDER_DETAILS_EDIT">
          <AdminButtonComponent
            disabled={editLoading || cannotEditProduceOrCannotDiscount}
            onClick={onEditProduce}
            className={classes.editButton}
            title={"update produce items"}
          />
        </PermissionWrapper>
      </Box>
      <hr className={classes.hr} />
      <Box className={classes.AmountBox}>
        <Box className={classes.AmountItemBox}>
          <Typography style={{ fontSize: 18, marginBottom: 20 }}>
            Outstanding Amount
          </Typography>
          <Typography
            style={{ color: "#E77228", fontSize: 16, marginBottom: 20 }}
          >
            <NumberFormatComponent
              prefix={orderData?.produces?.[0]?.currency?.symbol}
              value={
                orderData?.produces
                  .map((item) => item.totalAmount)
                  .reduce((prev, curr) => prev + curr, 0) || 0
              }
            />
          </Typography>
        </Box>
        {orderData?.vatAmount?<Box className={classes.AmountItemBox}>
          <Typography style={{ fontSize: 18, marginBottom: 20 }}>
            VAT
          </Typography>
          <Typography
            style={{ color: "#E77228", fontSize: 16, marginBottom: 20 }}
          >
            <NumberFormatComponent
              prefix={orderData?.produces?.[0]?.currency?.symbol}
              value={
              orderData?.vatAmount||""
              }
            />
          </Typography>
        </Box>:null}
        <Box className={classes.AmountItemBox}>
          <Typography style={{ fontSize: 18, marginBottom: 20 }}>
            Costs
          </Typography>
          <Typography
            style={{ color: "#E77228", marginBottom: 20, fontSize: 16 }}
          >
            <NumberFormatComponent
              prefix={orderData?.produces?.[0]?.currency?.symbol}
              value={
                orderData?.additionalCosts?.reduce(
                  (prev, curr) => prev + curr.amount,
                  0
                ) ?? 0
              }
            />
          </Typography>
        </Box>
        {calculatedVATSummary.map((item) => (
          <Box
            className={classes.AmountItemBox}
            key={`vatRate-${item.vatRate}`}
          >
            <Typography style={{ fontSize: 18, marginBottom: 20 }}>
              VAT {item.vatRate}%
            </Typography>
            <Typography
              style={{ color: "#E77228", marginBottom: 20, fontSize: 16 }}
            >
              <NumberFormatComponent
                prefix={orderData?.produces?.[0]?.currency?.symbol}
                value={item.totalVAT}
              />
            </Typography>
          </Box>
        ))}
        <Box className={`${classes.AmountItemBox} d-flex flex-row`}>
          <Typography style={{ fontSize: 18 }}>Discount</Typography>
          {orderData?._id && (
            <Typography className={`row ${classes.discountBox}`}>
              <div className="col-6">
                <ControlledComboBox
                  disabled={cannotEditProduceOrCannotDiscount}
                  className={classes.comboBox}
                  textLabel=""
                  defaultValue={
                    orderData?.discount?.discountType === "PERCENTAGE"
                      ? "Percentage"
                      : orderData?.discount?.discountType === "FIXED"
                      ? "Amount"
                      : ""
                  }
                  options={["Amount", "Percentage"]}
                  control={control}
                  name="discountType"
                />
              </div>
              {/* -
            {orderData?.discount?.discountType === "PERCENTAGE"
              ? "%"
              : orderData?.produces?.[0]?.currency?.symbol} */}
              <div className="col-3">
                <ControlledTextField
                  disabled={cannotEditProduceOrCannotDiscount}
                  className={classes.textField}
                  defaultValue={orderData?.discount?.discountTypeValue?.toString()}
                  control={control}
                  name="discountTypeValue"
                />
              </div>

              <div className="col-3">
                <PermissionWrapper permission="ORDERS_ORDER_DETAILS_EDIT">
                  <AdminButtonComponent
                    className={classes.updateDiscountButton}
                    disabled={
                      cannotEditProduceOrCannotDiscount || isUpdatingDiscount
                    }
                    title="Update"
                    onClick={onUpdateDiscount}
                  />
                </PermissionWrapper>
              </div>
            </Typography>
          )}
        </Box>
        <Box className={classes.AmountItemBox}>
          <Typography style={{ fontSize: 18, marginTop: 20 }}>
            Grand Total
          </Typography>
          <Typography style={{ color: "#E77228", marginTop: 20, fontSize: 16 }}>
            <NumberFormatComponent
              prefix={orderData?.produces?.[0]?.currency?.symbol}
              value={grandTotal || 0}
            />
          </Typography>
        </Box>
      </Box>

      {orderData?.invoices?.length ? (
        <Box className={classes.invoiceBox}>
          <Box className={classes.codeBox}>
            <Typography className={classes.invoiceBoxHeader}>
              Invoice
            </Typography>
          </Box>
          <Box className={classes.invoiceMapBox}>
            {invoices?.map((invoice, index) => {
              return <RenderInvoiceItem index={index} invoice={invoice} />;
            })}
          </Box>

          <AdminModalComponent
            children={renderAcceptRejectInvoiceModalBody()}
            headerTitle="Are you sure"
            openModal={openAcceptRejectConfirmModal}
            closeModal={onCloseAcceptRejectConfirmModal}
          />
          <AdminModalComponent
            children={renderForwardInvoiceModalBody()}
            headerTitle="Please select customer to forward"
            openModal={openForwardInvoiceModal}
            closeModal={onCloseForwardInvoiceModal}
          />
          <AdminModalComponent
            size="lg"
            children={renderGenerateCreditNoteModalBody()}
            headerTitle="Generate Credit Note"
            openModal={openGenerateCreditNoteModal}
            closeModal={onCloseGenerateCreditNoteModal}
          />
          <AdminModalComponent
            children={renderDeleteCreditNoteConfirmModalBody()}
            headerTitle="Are you sure"
            openModal={openDeleteCreditNoteConfirmModal}
            closeModal={onCloseDeleteCreditNoteConfirmModal}
          />

          <AdminModalComponent
            children={renderPaidConfirmModalBody()}
            headerTitle="Are you sure"
            openModal={openPaidConfirmModal}
            closeModal={onClosePaidConfirmModal}
          />
        </Box>
      ) : null}
      {/* <hr className={classes.hr} /> */}

      {/* <div className={classes.orderConfirmationBox}>
        {" "}
        {orderData?.confirmationFileUrl ? (
          <a
            href={orderData.confirmationFileUrl}
            target="_blank"
            className={classes.orderConfirmationText}
          >
            Order Confirmation File
          </a>
        ) : null}
      </div> */}
    </>
  );
};

export default ProduceInformationBox;
