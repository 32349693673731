import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import ControlledComboBox from "../../components/ControlledComboBox";
import LoadingIndicator from "../../components/LoadingIndicator";
import { Sidebar } from "../../components/Sidebar";
import {
  InformationCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/solid";
import {
  getSurplusSellersBuyers,
  OrderOrInvoiceList,
} from "../../services/surplus-allocation";
import * as yup from "yup";
import AdminButtonComponent from "../../components/AdminButtonComponent";
import { InvoiceStateType } from "../SurplusAllocation/interfaces";
import { toast } from "react-toastify";
import { useConfirmDialog } from "../AdminGroups/SelectConfirmDialog";
import { Checkbox, Chip, Tooltip } from "@mui/material";
import PermissionWrapper from "../../components/PermissionWrapper";
import { createServiceFeeInvoice } from "../../services/service-fee-invoices";
import ControlledTextField from "../../components/ControlledTextField";
import { InvoiceStatuses } from "../../constants";
import ControlledDateField from "../../components/ControlledDateField";
import moment from "moment";
import { Pagination } from "@mui/material";
import { CheckBox } from "@mui/icons-material";

interface BuyersSellersState {
  name: string;
  id: string;
}

const invoiceFilterSchema = yup.object().shape({
  buyer: yup.object(),
  seller: yup.object(),
  code: yup.string().nullable(),
  status: yup.array(),
  offerFrom: yup.string().nullable(),
  offerTo: yup.string().nullable(),
  createFrom: yup.string().nullable(),
  createTo: yup.string().nullable(),
  invoiceDateFrom: yup.string().nullable(),
  invoiceDateTo: yup.string().nullable(),
});

const CreateNewServiceFeeInvoiceContainer = () => {
  const {
    control: invoiceFilterControl,
    watch: invoiceFilterWatch,
    reset: invoiceFilterReset,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(invoiceFilterSchema),
    defaultValues: {
      buyer: {},
      seller: {},
    },
  });

  const watchBuyer = invoiceFilterWatch("buyer");
  const watchSeller = invoiceFilterWatch("seller");

  const [loading, setLoading] = useState(false);
  const [isCreating, setCreating] = useState(false);
  const PAGE_SIZE = 10;
  const [totalItems, setTotalItems] = useState(0);
  const [currenctPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [allBuyers, setAllBuyers] = useState<BuyersSellersState[]>([]);
  const [allSellers, setAllSellers] = useState<BuyersSellersState[]>([]);

  const [invoices, setInvoices] = useState<InvoiceStateType[]>([]);
  const [selectedInvoices, setSelectedInvoices] = useState<InvoiceStateType[]>(
    []
  );
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [selectedRowsFee, setSelectedRowsFee] = useState<string[]>([]);

  const [selectedInvoicesCollapsed, setSelectedInvoicesCollapsed] =
    useState(false);
  const [invoicesCollapsed, setInvoicesCollapsed] = useState(false);

  const {
    ConfirmDialog: NewSearchConfirmDialog,
    openDialog: openNewSearchDialog,
  } = useConfirmDialog({
    title: "You Are About to Discard Changes",
    description:
      "You have already selected invoices. If you want to select different buyer/seller, you will lose all selected invoices. Are you sure you want to proceed?",
  });

  useEffect(() => {
    const getAllSellersBuyers = async () => {
      const response = await getSurplusSellersBuyers("invoice");
      if (response) {
        setAllBuyers(
          response.buyers.map((item: any) => ({
            name: item?.companyName,
            id: item?.id,
          }))
        );
        setAllSellers(
          response.sellers.map((item: any) => ({
            name: item?.companyName,
            id: item.id,
          }))
        );
      }
    };
    getAllSellersBuyers();
  }, []);

  console.log(selectedInvoices);
  const onCreateServiceFeeInvoiceClick = async () => {
    const _selectedInvoices = selectedInvoices.filter((item) =>
      selectedRowsFee.includes(item.id)
    );

    if (_selectedInvoices.length === 0) {
      toast.error("Please select invoices to create service fee invoice.");
      return;
    }

    const currenctBuyer = _selectedInvoices[0].buyerCompany;
    const currentSeller = _selectedInvoices[0].sellerCompany;

    try {
      setCreating(true);
      const response = await createServiceFeeInvoice({
        invoices: _selectedInvoices.map((item) => ({
          invoiceId: item.id,
          fee: item.invoice_offer[0].payment_option
            ? Number(
                item.invoice_offer[0].payment_option?.amount_details?.platform_fee_percentage.toFixed(
                  2
                )
              )
            : 0,
        })),
        sellerCompanyId: currentSeller._id,
        buyerCompanyId: currenctBuyer._id,
        currencySymbol:
          _selectedInvoices[0].invoice_offer[0].produces[0].currency.symbol,
      });

      if (response?.success) {
        const invoiceUrl = response.url;
        window.open(invoiceUrl, "_blank");
        setSelectedRowsFee([]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setCreating(false);
    }
  };

  const onPageNumberChange = async (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    const data = getValues();
    setCurrentPage(page);

    //@ts-ignore
    const buyerId = data.buyer?.id;
    //@ts-ignore
    const sellerId = data.seller?.id;

    if (
      selectedInvoices.length > 0 &&
      buyerId &&
      sellerId &&
      buyerId !== selectedInvoices[0].buyerCompany._id &&
      sellerId !== selectedInvoices[0].sellerCompany._id
    ) {
      const result = await openNewSearchDialog();
      if (!result) {
        return;
      }
    }

    setLoading(true);
    const response = await OrderOrInvoiceList(
      "invoice",
      {
        code: data?.code ? data?.code : undefined,
        buyer: [buyerId],
        status: data?.status?.length ? data?.status : undefined,
        seller: [sellerId],
        offerTo: data?.offerTo
          ? moment(data?.offerTo).format("YYYY-DD-MM")
          : undefined,
        offerFrom: data?.offerFrom
          ? moment(data?.offerFrom).format("YYYY-DD-MM")
          : undefined,
        createTo: data?.createTo
          ? moment(data?.createTo).format("YYYY-DD-MM")
          : undefined,
        createFrom: data?.createFrom
          ? moment(data?.createFrom).format("YYYY-DD-MM")
          : undefined,
        invoiceDateFrom: data?.invoiceDateFrom
          ? moment(data?.invoiceDateFrom).format("YYYY-DD-MM")
          : undefined,
        invoiceDateTo: data?.invoiceDateTo
          ? moment(data?.invoiceDateTo).format("YYYY-DD-MM")
          : undefined,
      },
      page,
      undefined,
      PAGE_SIZE
    );
    setLoading(false);

    if (response) {
      setTotalItems(response?.metaData.total);
      setInvoices(response?.items);
      setTotalPages(response?.metaData.totalPage);

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const onListInvoicesClick = async (data: any) => {
    setCurrentPage(1);

    const buyerId = data.buyer.id;
    const sellerId = data.seller.id;

    if (selectedInvoices.length > 0) {
      const result = await openNewSearchDialog();
      if (!result) {
        return;
      }
    }

    setLoading(true);
    const response = await OrderOrInvoiceList(
      "invoice",
      {
        code: data?.code ? data?.code : undefined,
        buyer: [buyerId],
        status: data?.status?.length ? data?.status : undefined,
        seller: [sellerId],
        offerTo: data?.offerTo
          ? moment(data?.offerTo).format("YYYY-DD-MM")
          : undefined,
        offerFrom: data?.offerFrom
          ? moment(data?.offerFrom).format("YYYY-DD-MM")
          : undefined,
        createTo: data?.createTo
          ? moment(data?.createTo).format("YYYY-DD-MM")
          : undefined,
        createFrom: data?.createFrom
          ? moment(data?.createFrom).format("YYYY-DD-MM")
          : undefined,
        invoiceDateFrom: data?.invoiceDateFrom
          ? moment(data?.invoiceDateFrom).format("YYYY-DD-MM")
          : undefined,
        invoiceDateTo: data?.invoiceDateTo
          ? moment(data?.invoiceDateTo).format("YYYY-DD-MM")
          : undefined,
      },
      currenctPage,
      undefined,
      PAGE_SIZE
    );
    setLoading(false);

    if (response) {
      if (selectedInvoices.length > 0) {
        setSelectedRowsFee([]);
        setSelectedRows([]);
      }

      setTotalItems(response?.metaData.total);
      setInvoices(response?.items);
      setTotalPages(response?.metaData.totalPage);

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <Sidebar pageTitle="Create Service Fee Invoice">
      {loading && <LoadingIndicator title="" loading />}
      <Helmet>
        <title>Create Service Fee Invoice | FruPro Admin Portal</title>
      </Helmet>
      <PermissionWrapper permission="SERVICE_FEE_INVOICES_ADD">
        <div className="flex justify-start gap-8">
          <div className="flex w-full flex-col items-start">
            <div className="flex gap-4 flex-col bg-white p-8 rounded-lg">
              {invoices.length === 0 && selectedInvoices.length === 0 && (
                <div className="flex gap-1 items-center">
                  <InformationCircleIcon
                    className="h-8 w-8 text-blue-500"
                    aria-hidden="true"
                  />
                  <h1 className="text-md font-medium text-gray-900">
                    Please select buyer and seller to list invoices.
                  </h1>
                </div>
              )}
              <div className="flex gap-4 ">
                <ControlledComboBox
                  control={invoiceFilterControl}
                  options={allSellers}
                  name="seller"
                  textLabel="Seller"
                />
                <ControlledComboBox
                  control={invoiceFilterControl}
                  options={allBuyers}
                  name="buyer"
                  textLabel="Buyer"
                />
                <ControlledTextField
                  control={invoiceFilterControl}
                  name="code"
                  textLabel="Code"
                />
                <ControlledComboBox
                  control={invoiceFilterControl}
                  multiple
                  options={InvoiceStatuses.map((i) => i.name)}
                  name="status"
                  textLabel="Status"
                />
              </div>

              <div className="flex gap-4">
                <ControlledDateField
                  textLabel="Create Date(From - To)"
                  control={invoiceFilterControl}
                  name="createFrom"
                />
                <ControlledDateField
                  textLabel=""
                  control={invoiceFilterControl}
                  name="createTo"
                />
                <ControlledDateField
                  textLabel="Offer Date(From - To)"
                  control={invoiceFilterControl}
                  name="offerFrom"
                />
                <ControlledDateField
                  textLabel=""
                  control={invoiceFilterControl}
                  name="offerTo"
                />
                <ControlledDateField
                  textLabel="Invoice Date(From - To)"
                  control={invoiceFilterControl}
                  name="invoiceDateFrom"
                />
                <ControlledDateField
                  textLabel=""
                  control={invoiceFilterControl}
                  name="invoiceDateTo"
                />
              </div>
              <div className="flex gap-2 self-end">
                <AdminButtonComponent
                  title="List Invoices"
                  onClick={handleSubmit(onListInvoicesClick)}
                />
              </div>
            </div>
          </div>
          <Tooltip title="This button helps to reset all values to perform new searches. When you click on it, you'll lose all fetched and selected invoices.">
            <div className="self-start">
              <AdminButtonComponent
                title="Reset"
                onClick={() => {
                  invoiceFilterReset();
                  setInvoices([]);
                  setSelectedInvoices([]);
                  setSelectedRows([]);
                  setSelectedRowsFee([]);
                }}
                type="error"
                variant="outlined"
              />
            </div>
          </Tooltip>
        </div>
        <div
          className="flex flex-col bg-white p-8 rounded-lg mt-8 hover:cursor-pointer transition-all"
          onClick={() => setSelectedInvoicesCollapsed((prev) => !prev)}
        >
          <div className="flex justify-between items-start h-12 hover:cursor-pointer">
            <h1 className="text-2xl font-bold text-gray-900">
              Selected Invoices{" "}
              {selectedInvoices.length > 0 && `(${selectedInvoices.length})`}
            </h1>
            <div className="flex">
              <AdminButtonComponent
                title={
                  selectedRowsFee.length
                    ? `Remove (${selectedRowsFee.length})`
                    : "Remove Selected"
                }
                disabled={selectedRowsFee.length === 0}
                //@ts-ignore
                onClick={(e: any) => {
                  // add selected rows to selected invoices
                  // remove selected rows from main list

                  e.stopPropagation();
                  const _selectedInvoices = selectedInvoices.filter((item) =>
                    selectedRowsFee.includes(item.id)
                  );
                  setInvoices((prevInvoices) => [
                    ..._selectedInvoices,
                    ...prevInvoices,
                  ]);
                  setSelectedInvoices((prevSelectedInvoices) =>
                    prevSelectedInvoices.filter(
                      (item) => !selectedRowsFee.includes(item.id)
                    )
                  );
                  setSelectedRowsFee([]);
                }}
              />
            </div>
          </div>
          {selectedInvoices.length > 0 ? (
            <div
              className={`flex flex-col ${
                selectedInvoicesCollapsed ? " max-h-0" : "max-h-screen mt-4"
              } transition-all  overflow-hidden	`}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex gap-4 w-full">
                <div className="flex gap-4 w-full p-4">
                  <div className="w-8 flex items-center">
                    <Checkbox
                      checked={
                        selectedRowsFee.length === selectedInvoices.length
                      }
                      indeterminate={
                        selectedRowsFee.length > 0 &&
                        selectedRowsFee.length < selectedInvoices.length
                      }
                      onChange={(e) => {
                        if (e.currentTarget.checked) {
                          setSelectedRowsFee(
                            selectedInvoices.map((item) => item.id)
                          );
                        } else {
                          setSelectedRowsFee([]);
                        }
                      }}
                    />
                  </div>
                  <div className="w-1/6 flex items-center">
                    <p className="text-lg font-bold text-gray-900">
                      Seller Company Name
                    </p>
                  </div>
                  <div className="w-1/6 flex items-center">
                    <p className="text-lg font-bold text-gray-900">
                      Buyer Company Name
                    </p>
                  </div>
                  <div className="w-1/3 flex items-center">
                    <p className="text-lg font-bold text-gray-900">Codes</p>
                  </div>
                  <div className="w-1/12 flex items-center">
                    <p className="text-lg font-bold text-gray-900">
                      Total Amount
                    </p>
                  </div>
                  <div className="w-1/6 flex items-center">
                    <p className="text-lg font-bold text-gray-900">FruPay</p>
                  </div>
                  <div className="w-1/12 flex items-center">
                    <p className="text-lg font-bold text-gray-900">
                      Fee Percentage
                    </p>
                  </div>
                </div>
              </div>
              {selectedInvoices.map((invoice) => {
                return (
                  <div
                    className="flex gap-4 w-full rounded-lg p-4"
                    key={invoice.id}
                  >
                    <div className="flex gap-4 w-full">
                      <div className="w-8 flex items-center">
                        <Checkbox
                          checked={selectedRowsFee.includes(invoice.id)}
                          onChange={(e) => {
                            if (e.currentTarget.checked) {
                              setSelectedRowsFee([...selectedRows, invoice.id]);
                            } else {
                              setSelectedRowsFee(
                                selectedRowsFee.filter(
                                  (item) => item !== invoice.id
                                )
                              );
                            }
                          }}
                        />
                      </div>
                      <div className="w-1/6 flex items-center">
                        <p className="text-md font-medium text-gray-900">
                          {invoice.sellerCompany.companyName}
                        </p>
                      </div>
                      <div className="w-1/6 flex items-center">
                        <p className="text-md font-medium text-gray-900">
                          {invoice.buyerCompany.companyName}
                        </p>
                      </div>
                      <div className="flex flex-col w-1/3 justify-center">
                        <div className="text-md font-medium text-gray-900 flex gap-1">
                          <p className="font-bold">Payment:</p>
                          <p className="">
                            {invoice.invoice_offer[0].offerCode}
                          </p>
                        </div>
                        <div className="text-md font-medium text-gray-900 flex gap-1">
                          <p className="font-bold">Offer:</p>
                          <p className="">{invoice.offerCode}</p>
                        </div>
                        <div className="text-md font-medium text-gray-900 flex gap-1">
                          <p className="font-bold">Invoice:</p>
                          <p className="">{invoice.code}</p>
                        </div>
                      </div>
                      <div className="w-1/12 flex items-center">
                        <p className="text-md font-medium text-gray-900">
                          {invoice.invoice_offer[0].produces[0].currency.symbol}
                          {invoice.grandTotalAmount.toFixed(2)}
                        </p>
                      </div>
                      <div className="w-1/6 flex items-center">
                        <Chip
                          color="primary"
                          variant={
                            invoice.invoice_offer[0].useRefactoring
                              ? "filled"
                              : "outlined"
                          }
                          label={
                            invoice.invoice_offer[0].useRefactoring
                              ? "With FruPay"
                              : "No FruPay"
                          }
                        />
                      </div>
                      <div
                        className="w-1/12 flex items-center"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <input
                          type="number"
                          className="border border-gray-300 w-full p-2 rounded-lg"
                          defaultValue={
                            invoice.invoice_offer[0]?.payment_option?.amount_details?.platform_fee_percentage?.toFixed(
                              2
                            ) ?? "0.00"
                          }
                          onBlur={(e) => {
                            const newInvoices = selectedInvoices.map((item) => {
                              if (item.id === invoice.id) {
                                return {
                                  ...item,
                                  invoice_offer: [
                                    {
                                      ...item.invoice_offer[0],
                                      payment_option: {
                                        ...item.invoice_offer[0].payment_option,
                                        amount_details: {
                                          ...item.invoice_offer[0]
                                            .payment_option?.amount_details,
                                          platform_fee_percentage: parseFloat(
                                            e.currentTarget.value
                                          ),
                                        },
                                      },
                                    },
                                  ],
                                };
                              }
                              return item;
                            });
                            setSelectedInvoices(newInvoices);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
              <AdminButtonComponent
                title={
                  selectedRowsFee.length > 0
                    ? `Create Service Fee Invoice for ${selectedRowsFee.length} Selected Invoice(s)`
                    : "Create Service Fee Invoice"
                }
                onClick={onCreateServiceFeeInvoiceClick}
                disabled={selectedRowsFee.length === 0 || isCreating}
                type="success"
                loading={isCreating}
                className="mt-4"
              />
            </div>
          ) : watchBuyer && watchSeller ? (
            <p>
              You haven't selected any invoice yet. Please select invoices from
              "Invoices" table first.
            </p>
          ) : (
            <></>
          )}
        </div>
        <div
          className="flex flex-col bg-white p-8 rounded-lg mt-8 hover:cursor-pointer transition-all"
          onClick={() => setInvoicesCollapsed((prev) => !prev)}
        >
          <div className="flex justify-between items-start h-12">
            <div className="flex gap-1 items-center justify-center">
              <h1 className="text-2xl font-bold text-gray-900">Invoices</h1>
              {invoices.length > 0 &&
                invoices.some(
                  (invoice) =>
                    invoice.invoice_offer[0].produces[0].currency.name !==
                    invoices[0].invoice_offer[0].produces[0].currency.name
                ) && (
                  <Tooltip title="Not all invoices have the same currency. You can't add invoices with different currencies.">
                    <ExclamationCircleIcon className="w-6 h-6 mt-1 text-yellow-400" />
                  </Tooltip>
                )}
            </div>
            <div className="flex">
              <AdminButtonComponent
                title={
                  selectedRows.length
                    ? `Add (${selectedRows.length})`
                    : "Add Selected"
                }
                disabled={selectedRows.length === 0}
                //@ts-ignore
                onClick={(e: any) => {
                  // add selected rows to selected invoices
                  // remove selected rows from main list

                  e.stopPropagation();

                  const _selectedInvoices = invoices.filter((item) =>
                    selectedRows.includes(item.id)
                  );
                  setSelectedInvoices((prevSelectedInvoices) => [
                    ..._selectedInvoices,
                    ...prevSelectedInvoices,
                  ]);
                  if (selectedRowsFee.length === 0) {
                    setSelectedRowsFee(selectedRows);
                  }

                  setInvoices((prevInvoices) =>
                    prevInvoices.filter(
                      (item) => !selectedRows.includes(item.id)
                    )
                  );
                  setSelectedRows([]);
                }}
              />
            </div>
          </div>
          {invoices.length > 0 ? (
            <div
              className={`flex flex-col ${
                invoicesCollapsed ? " max-h-0" : "max-h-screen mt-4"
              } transition-all  overflow-hidden	`}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex gap-4 w-full">
                <div className="flex gap-4 w-full p-4">
                  <div className="w-8 flex items-center">
                    {invoices.length > 0 &&
                    invoices.some(
                      (invoice) =>
                        invoice.invoice_offer[0].produces[0].currency.name !==
                        invoices[0].invoice_offer[0].produces[0].currency.name
                    ) ? (
                      <Tooltip title="Checking all invoices is disabled since not all invoices have same currency.">
                        <Checkbox
                          checked={selectedRows.length === invoices.length}
                          disabled
                          indeterminate={
                            selectedRows.length > 0 &&
                            selectedRows.length < invoices.length
                          }
                          onChange={(e) => {
                            if (e.currentTarget.checked) {
                              setSelectedRows(invoices.map((item) => item.id));
                            } else {
                              setSelectedRows([]);
                            }
                          }}
                        />
                      </Tooltip>
                    ) : (
                      <Checkbox
                        checked={selectedRows.length === invoices.length}
                        indeterminate={
                          selectedRows.length > 0 &&
                          selectedRows.length < invoices.length
                        }
                        onChange={(e) => {
                          if (e.currentTarget.checked) {
                            setSelectedRows(invoices.map((item) => item.id));
                          } else {
                            setSelectedRows([]);
                          }
                        }}
                      />
                    )}
                  </div>
                  <div className="w-1/6 flex items-center">
                    <p className="text-lg font-bold text-gray-900">
                      Seller Company Name
                    </p>
                  </div>
                  <div className="w-1/6 flex items-center">
                    <p className="text-lg font-bold text-gray-900">
                      Buyer Company Name
                    </p>
                  </div>
                  <div className="w-1/3 flex items-center">
                    <p className="text-lg font-bold text-gray-900">Codes</p>
                  </div>
                  <div className="w-1/12 flex items-center">
                    <p className="text-lg font-bold text-gray-900">
                      Total Amount
                    </p>
                  </div>
                  <div className="w-1/6 flex items-center">
                    <p className="text-lg font-bold text-gray-900">FruPay</p>
                  </div>
                  <div className="w-1/12 flex items-center">
                    <p className="text-lg font-bold text-gray-900">
                      Fee Percentage
                    </p>
                  </div>
                </div>
              </div>
              {invoices.map((invoice) => {
                if (selectedInvoices.find((inv) => inv.id === invoice.id)) {
                  return null;
                }
                const selectedFirstInvoice = selectedInvoices?.[0];

                const disabled =
                  selectedFirstInvoice &&
                  selectedFirstInvoice.invoice_offer[0].produces[0].currency
                    .name !==
                    invoice.invoice_offer[0].produces[0].currency.name;

                const invoicesHasDifferentCurrency =
                  invoices.length > 0 &&
                  invoices.some(
                    (invoice) =>
                      invoice.invoice_offer[0].produces[0].currency.name !==
                      invoices[0].invoice_offer[0].produces[0].currency.name
                  );

                const disableSelectingThisInvoice =
                  invoicesHasDifferentCurrency &&
                  selectedRows.length > 0 &&
                  invoices.find((inv) => inv.id === selectedRows[0])
                    ?.produces[0].currency.name !==
                    invoice.produces[0].currency.name;

                return (
                  <div
                    className={`flex gap-4 w-full rounded-lg p-4 ${
                      disableSelectingThisInvoice ? "opacity-50" : ""
                    }`}
                    key={invoice.id}
                  >
                    <div className="flex gap-4 w-full">
                      <div className="w-8 flex items-center">
                        <Checkbox
                          checked={selectedRows.includes(invoice.id)}
                          disabled={disabled || disableSelectingThisInvoice}
                          onChange={(e) => {
                            if (disabled) {
                              toast.error(
                                "You can't add invoices with different currencies."
                              );
                              return;
                            }

                            if (e.currentTarget.checked) {
                              setSelectedRows([...selectedRows, invoice.id]);
                            } else {
                              setSelectedRows(
                                selectedRows.filter(
                                  (item) => item !== invoice.id
                                )
                              );
                            }
                          }}
                        />
                      </div>
                      <div className="w-1/6 flex items-center">
                        <p className="text-md font-medium text-gray-900">
                          {invoice.sellerCompany.companyName}
                        </p>
                      </div>
                      <div className="w-1/6 flex items-center">
                        <p className="text-md font-medium text-gray-900">
                          {invoice.buyerCompany.companyName}
                        </p>
                      </div>
                      <div className="flex flex-col w-1/3 justify-center">
                        <div className="text-md font-medium text-gray-900 flex gap-1">
                          <p className="font-bold">Payment:</p>
                          <p className="">
                            {invoice.invoice_offer[0].offerCode}
                          </p>
                        </div>
                        <div className="text-md font-medium text-gray-900 flex gap-1">
                          <p className="font-bold">Offer:</p>
                          <p className="">{invoice.offerCode}</p>
                        </div>
                        <div className="text-md font-medium text-gray-900 flex gap-1">
                          <p className="font-bold">Invoice:</p>
                          <p className="">{invoice.code}</p>
                        </div>
                      </div>
                      <div className="w-1/12 flex items-center">
                        <p className="text-md font-medium text-gray-900">
                          {invoice.invoice_offer[0].produces[0].currency.symbol}
                          {invoice.grandTotalAmount.toFixed(2)}
                        </p>
                      </div>
                      <div className="w-1/6 flex items-center">
                        <Chip
                          color="primary"
                          variant={
                            invoice.invoice_offer[0].useRefactoring
                              ? "filled"
                              : "outlined"
                          }
                          label={
                            invoice.invoice_offer[0].useRefactoring
                              ? "With FruPay"
                              : "No FruPay"
                          }
                        />
                      </div>
                      <div
                        className="w-1/12 flex items-center"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <input
                          type="number"
                          className="border border-gray-300 w-full p-2 rounded-lg"
                          defaultValue={
                            invoice.invoice_offer[0]?.payment_option?.amount_details?.platform_fee_percentage?.toFixed(
                              2
                            ) ?? "0.00"
                          }
                          disabled={disabled}
                          onBlur={(e) => {
                            const newInvoices = invoices.map((item) => {
                              if (item.id === invoice.id) {
                                return {
                                  ...item,
                                  invoice_offer: [
                                    {
                                      ...item.invoice_offer[0],
                                      payment_option: {
                                        ...item.invoice_offer[0].payment_option,
                                        amount_details: {
                                          ...item.invoice_offer[0]
                                            .payment_option?.amount_details,
                                          platform_fee_percentage: parseFloat(
                                            e.currentTarget.value
                                          ),
                                        },
                                      },
                                    },
                                  ],
                                };
                              }
                              return item;
                            });
                            setInvoices(newInvoices);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
              <div className="flex self-center mt-8">
                <Pagination
                  onChange={(
                    event: React.ChangeEvent<unknown>,
                    page: number
                  ) => {
                    onPageNumberChange(event, page);
                  }}
                  count={totalPages}
                  page={currenctPage || 1}
                />
              </div>
            </div>
          ) : selectedInvoices.length > 0 ? (
            <p>There are no more invoices to select.</p>
          ) : watchBuyer && watchSeller ? (
            <p>No invoice found.</p>
          ) : (
            <></>
          )}
        </div>
        {NewSearchConfirmDialog}
      </PermissionWrapper>
    </Sidebar>
  );
};

export default CreateNewServiceFeeInvoiceContainer;
